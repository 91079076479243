'use client';

import React from 'react';

import type { CommonPageLayoutFragment } from '@haaretz/s-fragments/CommonPageLayout';

export type PageAtomType = {
  pageContentId: CommonPageLayoutFragment['contentId'];
  articleSectionId?: string;
} | null;

const PageContext = React.createContext<PageAtomType | undefined>(undefined);
const PageSetter = React.createContext<((value: PageAtomType) => void) | undefined>(undefined);

export function PageProvider({
  children,
  value: defaultPage,
}: React.PropsWithChildren<{ value: PageAtomType }>) {
  const [page, setPage] = React.useState<PageAtomType>(defaultPage);

  const pageSetter = React.useCallback((value: PageAtomType) => {
    setPage(value);
  }, []);

  return (
    <PageContext.Provider value={page}>
      <PageSetter.Provider value={pageSetter}>{children}</PageSetter.Provider>
    </PageContext.Provider>
  );
}

export default function usePage() {
  const context = React.useContext(PageContext);

  if (context === undefined) {
    throw new Error('usePage must be used within a PageProvider');
  }

  return context;
}

export function useSetPage() {
  const context = React.useContext(PageSetter);

  if (context === undefined) {
    throw new Error('useSetPage must be used within a PageProvider');
  }

  return context;
}

export function usePageAtom() {
  return [usePage(), useSetPage()] as const;
}
