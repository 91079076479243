'use client';

import React from 'react';

type PageStatusToken = string | null;

const PageStatusTokenContext = React.createContext<PageStatusToken | undefined>(undefined);
const PageStatusTokenSetter = React.createContext<((value: PageStatusToken) => void) | undefined>(
  undefined
);

export function PageStatusTokenProvider({
  children,
  value: defaultPageStatusToken,
}: React.PropsWithChildren<{ value: PageStatusToken }>) {
  const [pageStatusToken, setPageStatusToken] =
    React.useState<PageStatusToken>(defaultPageStatusToken);

  const pageStatusTokenSetter = React.useCallback((value: PageStatusToken) => {
    setPageStatusToken(value);
  }, []);

  return (
    <PageStatusTokenContext.Provider value={pageStatusToken}>
      <PageStatusTokenSetter.Provider value={pageStatusTokenSetter}>
        {children}
      </PageStatusTokenSetter.Provider>
    </PageStatusTokenContext.Provider>
  );
}

export default function usePageStatusToken() {
  const context = React.useContext(PageStatusTokenContext);

  if (context === undefined) {
    throw new Error('usePageStatusToken must be used within a PageStatusTokenProvider');
  }

  return context;
}

export function useSetPageStatusToken() {
  const context = React.useContext(PageStatusTokenSetter);

  if (context === undefined) {
    throw new Error('useSetPageStatusToken must be used within a PageStatusTokenProvider');
  }

  return context;
}

export function usePageStatusTokenAtom() {
  return [usePageStatusToken(), useSetPageStatusToken()] as const;
}
