'use client';

import React from 'react';

type PreviewIdValue = string | null;

const PreviewIdContext = React.createContext<PreviewIdValue | undefined>(undefined);
const PreviewIdSetter = React.createContext<((previewId: PreviewIdValue) => void) | undefined>(
  undefined
);

export function PreviewIdProvider({
  children,
  value: defaultPreviewId,
}: React.PropsWithChildren<{ value: PreviewIdValue }>) {
  const [previewId, setPreviewId] = React.useState<PreviewIdValue>(defaultPreviewId);

  const previewIdSetter = React.useCallback((value: PreviewIdValue) => {
    const newPreviewId = typeof value === 'string' ? value : null;

    setPreviewId(newPreviewId);
  }, []);

  return (
    <PreviewIdContext.Provider value={previewId}>
      <PreviewIdSetter.Provider value={previewIdSetter}>{children}</PreviewIdSetter.Provider>
    </PreviewIdContext.Provider>
  );
}

export default function usePreviewId() {
  const context = React.useContext(PreviewIdContext);

  if (context === undefined) {
    throw new Error('usePreviewId must be used within a PreviewIdProvider');
  }

  return context;
}

export function useSetPreviewId() {
  const context = React.useContext(PreviewIdSetter);

  if (context === undefined) {
    throw new Error('useSetPreviewId must be used within a PreviewIdProvider');
  }

  return context;
}

export function usePreviewIdAtom() {
  return [usePreviewId(), useSetPreviewId()] as const;
}
