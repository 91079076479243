'use client';

import React from 'react';

const ClientTeaserOnPageContext = React.createContext<
  | {
      getExcludeString: (serverTeaserIds?: string | null) => string;
    }
  | undefined
>(undefined);
const ClientTeaserOnPageUpdator = React.createContext<
  | {
      addClientTeasers: (teaserIds: string[]) => void;
      clearClientTeasers: () => void;
    }
  | undefined
>(undefined);

export function ClientTeaserOnPageProvider({ children }: React.PropsWithChildren) {
  const [clientTeaserOnPage, setClientTeaserOnPage] = React.useState<Set<string>>(
    new Set<string>()
  );

  const teaserOnPageActions = React.useMemo(
    () => ({
      getExcludeString(serverTeaserIds?: string | null) {
        return [...clientTeaserOnPage, ...(serverTeaserIds ? [serverTeaserIds] : [])].toString();
      },
    }),
    [clientTeaserOnPage]
  );

  const teaserOnPageUpdators = React.useMemo(
    () => ({
      addClientTeasers: (teaserIds: string[]) => {
        setClientTeaserOnPage(prev => new Set<string>([...prev, ...teaserIds]));
      },
      clearClientTeasers: () => {
        setClientTeaserOnPage(new Set<string>());
      },
    }),
    []
  );

  return (
    <ClientTeaserOnPageContext.Provider value={teaserOnPageActions}>
      <ClientTeaserOnPageUpdator.Provider value={teaserOnPageUpdators}>
        {children}
      </ClientTeaserOnPageUpdator.Provider>
    </ClientTeaserOnPageContext.Provider>
  );
}

export default function useGettersClientTeasersOnPage() {
  const context = React.useContext(ClientTeaserOnPageContext);

  if (context === undefined) {
    throw new Error(
      'useGettersClientTeasersOnPage must be used within a ClientTeaserOnPageProvider'
    );
  }

  return context;
}

export function useMutatorsClientTeasersOnPage() {
  const context = React.useContext(ClientTeaserOnPageUpdator);

  if (context === undefined) {
    throw new Error(
      'useMutatorsClientTeasersOnPage must be used within a ClientTeaserOnPageProvider'
    );
  }

  return context;
}

export function useClientTeasersOnPageAtom() {
  return [useGettersClientTeasersOnPage(), useMutatorsClientTeasersOnPage()] as const;
}
